
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        










































.pixels {
  background: $--color-primary;

  .pixel {
    padding-top: 120px;
    height: calc(90vh - 160px);
    height: calc(90 * var(--vh, 1vh) - 160px);
    min-height: 350px;
    align-items: center;
    animation-delay: 0.7s;
    display: flex;
    justify-content: center;
    width: 100%;
    z-index: 15;
    backface-visibility: hidden;

    #platform {
      overflow: visible;
      margin-top: 60px;
      height: auto;
      width: 600px;
      padding: 40px;

      .cls-2 {
        fill: #f3f5fd;
      }

      .cls-3 {
        fill: #c9c9c9;
      }

      .cls-4 {
        fill: #5072ff;
      }

      .cls-5 {
        fill: #1e3bb0;
      }

      .cls-6 {
        fill: #bcbcbc;
      }

      .logo-shadow {
        animation: logoShadow 4s infinite ease-in-out;
        transform: translate(0, 0);
      }

      .logo {
        animation: logo 4s infinite ease-in-out;
        transform: translate(0, 0);
      }

      .main-box {
        animation: pixel 4s infinite ease-in-out;
        transform: translate(0, 0);
      }

      .main-box-shadow {
        animation: pixelShadow 4s infinite ease-in-out;
        transform: translate(0, -8px);
      }
    }
  }

  @keyframes logoShadow {
    0% {
      opacity: 1;
      transform: translate(0, 0);
    }

    50% {
      opacity: 0.6;
      transform: translate(0, -16px);
    }

    100% {
      opacity: 1;
      transform: translate(0, 0);
    }
  }

  @keyframes logo {
    0% {
      transform: translate(0, -2px);
    }

    50% {
      transform: translate(0, -32px);
    }

    100% {
      transform: translate(0, -2px);
    }
  }

  @keyframes pixel {
    0% {
      transform: translate(0, 0);
    }

    50% {
      transform: translate(0, -16px);
    }

    100% {
      transform: translate(0, 0);
    }
  }

  @keyframes pixelShadow {
    0% {
      opacity: 0.4;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.4;
    }
  }
}
