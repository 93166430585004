
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        
























































































.shop-int-subheader {
  line-height: 22px;
  margin-bottom: $--clb-layout-3 - 2;
  text-align: left;
}

.el-button + .el-button {
  margin-left: 0;
}

.icon-cont {
  overflow: hidden;
  padding: 24px 45px 0;

  .shopify-logo {
    height: 67px;
    width: 67px;
  }

  .refresh-icon {
    font-size: 46px;
  }

  .logo {
    color: $--jb-dark-primary-color;
    height: 58px;
    width: 58px;
  }

  .logos-span {
    align-items: center;
    display: flex;
    justify-content: space-around;
    transition: transform 0.5s cubic-bezier(0.72, -0.61, 0.25, 1.51);
  }

  .el-icon-circle-check {
    color: green;
    font-size: 40px;
    opacity: 0;
    transform: translate3d(-300px, -50px, 0);
  }
}
// On click of "Yes", this runs
.sliding-animation {
  .logos-span {
    opacity: 0;
    transform: translateY(200px) scale(0.3);
    transition: 500ms ease-in-out;
  }

  .el-icon-circle-check {
    opacity: 1;
    transform: translate3d(0, -55px, 0) scale(1.5);
    transition: 0.9s cubic-bezier(0.72, -0.61, 0.25, 1.51);
  }
}
