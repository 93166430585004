
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        














































.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: opacity 0.1s, transform 0.2s;
}

.slide-fade-enter,
.slide-fade-leave-active {
  opacity: 0;
  transform: translateX(20px);
}

.shop-int-conf-container {
  margin: 0 auto 143px;
  max-width: 496px;

  .shop-int-conf-header {
    margin-bottom: 0;
  }

  .shop-int-subheader {
    font-size: 14px;
    margin-bottom: 32px;
    margin-top: 8px;
  }
}
