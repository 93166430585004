
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        











































































































































































.container-fluid {
  margin: 0;
  padding: 0;
}

.signin-form {
  background: #fff;
  position: absolute;
  padding: $--clb-layout-4 $--clb-mobile-padding $--clb-layout-6 $--clb-mobile-padding;
  z-index: 10;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100vh;

  .logo-link-portal {
    position: absolute;
    top: 0;
    left: 0;
    margin: $--clb-space-5 $--clb-space-5 0 $--clb-space-5;
  }

  .form-inner {
    width: 100%;
    max-height: 100%;
  }

  .main-header {
    color: $--clb-color__headings;
    font-size: 3rem;
    line-height: 3rem;
    font-weight: bold;
    margin: 0;
  }

  .auth-header {
    font-weight: 400;
    margin-bottom: 32px;
    margin-top: 8px;
  }

  .form-holder {
    position: relative;
  }

  .remember-password,
  .get-started,
  .actions {
    font-size: 14px;
    position: absolute;
    width: 100%;
    margin-bottom: 32px;

    .attention {
      letter-spacing: 0.5px;
    }
  }

  .pixels {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

@media (min-width: $--sm) {
  .signin-form {
    margin: 0 auto;
  }
}

@media (min-width: $--md) {
  .signin-form {
    padding-left: 40px;
    padding-right: 40px;
    width: 500px;

    .logo-link-portal {
      margin: 40px 40px 0 40px;
    }

    .headers,
    .form-holder {
      width: 100%;

      .main-header {
        margin: 0;
      }
    }

    .pixels {
      display: block;
      margin-left: 500px;
      width: calc(100% - 500px);
    }
  }
}

@media (min-width: $--lg) {
  .signin-form {
    width: 630px;

    .pixels {
      margin-left: 630px;
      width: calc(100% - 630px);
    }
  }
}
